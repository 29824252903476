@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');

html,
body,
#root,
.app,
.content {
	height: 100%;
	width: 100%;
	overflow-x: auto;
	font-family: 'Source Sans 3', sans-serif;
}

.app {
	display: flex;
	position: relative;
}

::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
}

/* Handle on Hover */
::-webkit-scrollbar-track:hover {
	background: #555;
}

@font-face {
	font-family: 'SKYfont-brands';
	src: url('./SKYfont-brands.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

.custom-font {
	font-family: 'SKYfont-brands' !important;
}
